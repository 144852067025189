import { storageHelper, storageKeys } from "../../app/storage-helper";
import jwt_decode from 'jwt-decode';
import { AuthUser } from "./auth.types";

export const authService = {
    storeAuthToken,
    extractAuthUserFromToken,
    clearAuthToken
}

function storeAuthToken(authToken: string) {
    storageHelper.setValue(storageKeys.authToken, authToken);

    const claims = extractRolesAndPermission(authToken);
    const tenantId = claims["sap/tenantid"];
    storageHelper.setValue(storageKeys.tenantId, tenantId);
}

function extractAuthUserFromToken() {
    const authToken = storageHelper.getValue(storageKeys.authToken);
    if (authToken) {
        const claims = extractRolesAndPermission(authToken);
        const permissions = claims["sap/permission"] || [];
        const email = claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress'];
        const givenName = claims['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname'];
        const tenantId = claims["sap/tenantid"];
        const role = claims["sap/role"];

        storageHelper.setValue(storageKeys.tenantId, tenantId);

        const user: AuthUser = {
            email,
            role,
            permissions,
            givenName,
            tenantId
        };

        return user;        
    }    
    return undefined;
}

function clearAuthToken() {
    storageHelper.removeValue(storageKeys.authToken);
    storageHelper.removeValue(storageKeys.tenantId);
}

function extractRolesAndPermission(token: string) {
    var decoded = jwt_decode<any>(token);
    return decoded;
}