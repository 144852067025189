import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

interface Props {
  value?: Date;
  onChange: (value: Date) => void;
  placeholder?: string;
  isClearable?: boolean;
  disabled?: boolean;
  timeSelect?: boolean;
  monthPicker?: boolean;
  yearPicker?: boolean;
  dateFormat?: string;
  showTimeSelectOnly?: boolean;
  minDate?: Date;
  maxDate?: Date;
}

export default function DateSelect({ value, onChange, placeholder, isClearable, disabled, monthPicker, yearPicker,
  timeSelect, dateFormat, showTimeSelectOnly, minDate, maxDate }: Props) {
  return <DatePicker selected={value}
    placeholderText={placeholder}
    wrapperClassName="form-control"
    className="form-control"
    dateFormat={dateFormat || (showTimeSelectOnly ? 'HH:mm' : timeSelect ? "dd/MM/yyyy HH:mm" : "d/MM/yyyy")}
    dropdownMode="select"
    showMonthDropdown
    showYearDropdown
    useShortMonthInDropdown
    isClearable={isClearable}
    disabled={disabled}
    showTimeSelect={timeSelect}
    showTimeSelectOnly={showTimeSelectOnly}
    showYearPicker={yearPicker}
    showMonthYearPicker={monthPicker}
    customTimeInput
    timeFormat="HH:mm"
    timeIntervals={15}
    minDate={minDate}
    maxDate={maxDate}
    onChange={onChange} />
}
