import { lazy } from "react";
import { Features, SapPermissions } from "../app/constants";

const TransactionEntryPage = lazy(() => import("../features/finance/components/TransactionEntryPage"));
const UserProfilePage = lazy(() => import("../features/users/components/UserProfilePage"));
const TransactionPage = lazy(() => import("../features/finance/components/TransactionPage"));
const WorkLogsPage = lazy(() => import("../features/worklog/components/WorkLogsPage"));
const ProjectsPage = lazy(() => import("../features/project/components/ProjectsPage"));
const LookupList = lazy(() => import("../features/lookup/components/LooksupList"));
const DashboardPage = lazy(() => import("../features/dashboard/components/DashboardPage"));
const HarvestLogsPage = lazy(() => import("../features/harvest/components/HarvestLogsPage"));
const EmployeesPage = lazy(() => import("../features/employees/components/EmployeesPage"));
const WorkLogsSummaryPage = lazy(() => import("../features/worklog/components/WorkLogsSummaryPage"));
const AllTenantsPage = lazy(() => import("../features/tenant/components/AllTenantsPage"));
const PayrollPage = lazy(() => import("../features/payroll/components/PayrollPage"));
const TreesPage = lazy(() => import("../features/crop-tracking/components/trees/TreesPage"));
const CropingSeasonsPage = lazy(
    () => import("../features/crop-tracking/components/croping-seasons/CropingSeasonsPage")
);
const CropStatsPage = lazy(() => import("../features/crop-tracking/components/crop-stats/CropStatsPage"));
const CropAnalyticsPage = lazy(() => import("../features/crop-tracking/components/crop-stats/CropAnalyticsPage"));
const AttendanceLogsPage = lazy(() => import("../features/attendance/components/AttendanceLogsPage"));
const DevicesPage = lazy(() => import("../features/attendance/components/AttendanceDevicesPage"));
const CurrentTenantPage = lazy(() => import("../features/tenant/components/CurrentTenantPage"));
const LeaveTypesPage = lazy(() => import("../features/leave/components/LeaveTypesPage"));
const LeaveManagementPage = lazy(() => import("../features/leave/components/LeaveManagementPage"));

export interface NavModule {
    name: string;
    path: string;
    feature?: string;
    elements: NavElement[];
    hideInMenu?: boolean;
}

export interface NavElement {
    id?: string;
    absolutePath?: string;
    name: string;
    path: string;
    icon: string;
    permission?: string;
    allowAnonymous?: boolean;
    feature?: string;
    component?: any;
    subElements?: NavElement[];
    hideInMenu?: boolean;
}

export const MAIN_NAV: NavModule[] = [
    {
        name: "Finance",
        path: "finance",
        elements: [
            {
                name: "Dashboard",
                path: "dashboard",
                icon: "fa-gauge-high",
                component: <DashboardPage />,
                permission: SapPermissions.transactionView,
            },
            {
                name: "Data Entry",
                path: "data-entry",
                icon: "fa-pen-to-square",
                component: <TransactionEntryPage />,
                permission: SapPermissions.transactionEntry,
                feature: Features.Finance.Transactions,
            },
            {
                name: "Analytics",
                path: "analytics",
                icon: "fa-chart-line",
                component: <TransactionPage />,
                permission: SapPermissions.transactionView,
                feature: Features.Finance.Transactions,
            },
        ],
    },
    {
        name: "Farming",
        path: "farming",
        elements: [
            {
                name: "Harvest Log",
                icon: "fa-wheat-awn",
                path: "harvest-log",
                component: <HarvestLogsPage />,
                permission: SapPermissions.harvestLogFullAccess,
                feature: Features.Farming.HarvestLog,
            },
            {
                name: "Crop Tracking",
                icon: "fa-seedling",
                path: "crop-tracking",
                permission: SapPermissions.cropTrackingFullAccess,
                feature: Features.Farming.CropTracking,
                subElements: [
                    {
                        name: "Trees",
                        icon: "fa-tree",
                        path: "trees",
                        component: <TreesPage />,
                    },
                    {
                        name: "Seasons",
                        icon: "fa-bars-staggered",
                        path: "cropping-seasons",
                        component: <CropingSeasonsPage />,
                    },
                    {
                        name: "Crop Logs",
                        icon: "fa-chart-bar",
                        path: "logs",
                        component: <CropStatsPage />,
                    },
                    {
                        name: "Crop Analytics",
                        icon: "fa-chart-line",
                        path: "analytics",
                        component: <CropAnalyticsPage />,
                    },
                ],
            },
        ],
    },
    {
        name: "Hr",
        path: "hr",
        elements: [
            {
                name: "Employees",
                path: "employees",
                icon: "fa-person-rays",
                component: <EmployeesPage />,
                feature: Features.HR.EmployeeManagement,
                permission: SapPermissions.employeesView,
            },
            {
                name: "Attendance",
                path: "attendance",
                icon: "fa-person-walking",
                component: <WorkLogsPage />,
                feature: Features.HR.WorkLogs,
                permission: SapPermissions.workLogsView,
            },
            {
                name: "Attendance Summary",
                path: "attendance-summary",
                icon: "fa-clipboard-list",
                component: <WorkLogsSummaryPage />,
                feature: Features.HR.WorkLogs,
                permission: SapPermissions.workLogsView,
            },
            {
                name: "Payroll",
                path: "payroll",
                icon: "fa-file-invoice-dollar",
                component: <PayrollPage />,
                feature: Features.HR.Payroll,
                permission: SapPermissions.payrollFullAccess,
            },
            {
                name: "Manage Leave",
                path: "leave-management",
                icon: "fa-umbrella-beach",
                component: <LeaveManagementPage />,
                feature: Features.HR.LeaveManagement,
                permission: SapPermissions.leaveManage,
            },
        ],
    },
    {
        name: "ADMIN",
        path: "admin",
        elements: [
            {
                name: "Finance",
                path: "finance",
                icon: "fa-money-bill-wave",
                permission: SapPermissions.adminControl,
                subElements: [
                    {
                        name: "Expense Types",
                        path: "expense-types",
                        icon: "fa-money-bill-wave",
                        component: <LookupList headerCode="EXPENSE_TYPES" lookupTitle="Expense Types" showSubTypes />,
                        feature: Features.Finance.Transactions,
                    },
                    {
                        name: "Income Types",
                        path: "income-types",
                        icon: "fa-sack-dollar",
                        component: <LookupList headerCode="INCOME_TYPES" lookupTitle="Income Types" showSubTypes />,
                        feature: Features.Finance.Transactions,
                    },
                ],
            },
            {
                name: "Farming",
                path: "farming",
                icon: "fa-seedling",
                permission: SapPermissions.adminControl,
                subElements: [
                    {
                        name: "Crop Fallen Reasons",
                        path: "crop-fallen-reasons",
                        icon: "fa-lemon",
                        component: <LookupList headerCode="CROP_FALLEN_REASONS" lookupTitle="Crop Fallen Reasons" />,
                        feature: Features.Farming.AdvancedCropTracking,
                    },
                ],
            },
            {
                name: "HR",
                path: "hr",
                icon: "fa-person-rays",
                permission: SapPermissions.adminControl,
                subElements: [
                    {
                        name: "Leave Types",
                        icon: "fa-umbrella-beach",
                        path: "leave-types",
                        component: <LeaveTypesPage />,
                        permission: SapPermissions.leaveTypesFullAccess,
                        feature: Features.HR.LeaveManagement,
                    },
                    {
                        name: "Devices",
                        icon: "fa-mobile-button",
                        path: "attendance-devices",
                        component: <DevicesPage />,
                        permission: SapPermissions.attendanceDevicesView,
                        feature: Features.HR.AttendanceDevices,
                    },
                    {
                        name: "Device Logs",
                        path: "device-logs",
                        icon: "fa-fingerprint",
                        feature: Features.HR.AttendanceDevices,
                        component: <AttendanceLogsPage />,
                        permission: SapPermissions.attendanceLogsView,
                    },
                ],
            },
            {
                name: "General",
                path: "general",
                icon: "fa-gear",
                permission: SapPermissions.adminControl,
                subElements: [
                    {
                        name: "Projects",
                        path: "projects",
                        icon: "fa-house",
                        component: <ProjectsPage />,
                        feature: Features.Finance.Transactions,
                        permission: SapPermissions.adminControl,
                    },
                    {
                        name: "Business Details",
                        path: "businesses",
                        icon: "fa-business-time",
                        component: <CurrentTenantPage />,
                        permission: SapPermissions.adminControl,
                    },
                ],
            },
        ],
    },
    {
        name: "Tenant Admin",
        path: "system-admin",
        elements: [
            {
                name: "Tenants",
                icon: "fa-building",
                path: "tenants",
                component: <AllTenantsPage />,
                permission: SapPermissions.manageTenants,
            },
            {
                name: "Attendance Devices",
                icon: "fa-mobile-button",
                path: "attendance-devices",
                component: <DevicesPage all />,
                permission: SapPermissions.attendanceDevicesFullAccess,
            },
        ],
    },
    {
        name: "User",
        path: "user",
        hideInMenu: true,
        elements: [
            {
                name: "Profile",
                icon: "",
                path: "profile",
                component: <UserProfilePage />,
            },
        ],
    },
];
