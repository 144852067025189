import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { ConfigState } from "./config.types";
import { getConfigValue } from "./config.api";

const initialState: ConfigState = {
    configValues: {}
}

export const getconfigValueAsync = createAsyncThunk(
    'config/value',
    async (key: string) => {
        const value = await getConfigValue(key);
        return { [key]: value };
    });

const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getconfigValueAsync.fulfilled, (state, action) => {
            state.configValues = { ...state.configValues, ...action.payload }
        })
    }
});

export const { } = configSlice.actions;

export default configSlice.reducer;

export const configValueSelector = (state: RootState) => {
    return (key: string) => state.config.configValues[key];
}
