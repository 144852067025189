import { coreApi } from "../../app/core-api";
import { ListItem, PagedResult, PagedSearchFilter } from "../../app/types";
import {
    BulkLeaveGenerateResult,
    EmployeeLeaveSet,
    EmployeeLeaveUpdateDto,
    LeaveFilter,
    LeaveType,
} from "./leave.types";

export const leaveApi = {
    listLeaveTypes,
    getLeaveType,
    getDefaultLeaveTypeIds,
    createLeaveType,
    updateLeaveType,
    deleteLeaveType,
    getLeaveTypesSelectList,
    createDefaultLeaveTypes,
    bulkPopulateLeave,
    searchEmployeeLeave,
    getEmployeeLeave,
    saveEmployeeLeave,
};

const controller = "Leave";

function listLeaveTypes() {
    return coreApi.get<LeaveType[]>(`${controller}/LeaveTypes`);
}

function getLeaveType(id: string) {
    return coreApi.get<LeaveType>(`${controller}/LeaveTypes/${id}`);
}

function getDefaultLeaveTypeIds() {
    return coreApi.get<ListItem[]>(`${controller}/LeaveTypes/Default`);
}

function createLeaveType(leaveType: LeaveType) {
    return coreApi.post<LeaveType>(`${controller}/LeaveTypes`, leaveType);
}

function updateLeaveType(id: string, leaveType: LeaveType) {
    return coreApi.put<LeaveType>(`${controller}/LeaveTypes/${id}`, leaveType);
}

function deleteLeaveType(id: string) {
    return coreApi.remove(`${controller}/LeaveTypes/${id}`);
}

function getLeaveTypesSelectList() {
    return coreApi.get<ListItem[]>(`${controller}/LeaveTypes/SelectList`);
}

function createDefaultLeaveTypes() {
    return coreApi.post(`${controller}/LeaveTypes/CreateDefaults`, {});
}

function bulkPopulateLeave(year: number) {
    return coreApi.post<BulkLeaveGenerateResult>(`${controller}/EmployeeLeaves/BulkPopulate/${year}`, {});
}

function searchEmployeeLeave(filter: PagedSearchFilter<LeaveFilter>) {
    return coreApi.get<PagedResult<EmployeeLeaveSet>>(`${controller}/EmployeeLeaves/Search`, {
        ...filter.filter,
        ...filter.pagination,
    });
}

function getEmployeeLeave(year: number, id: string) {
    return coreApi.get<EmployeeLeaveSet>(`${controller}/EmployeeLeaves/${year}/${id}`);
}

function saveEmployeeLeave(leaveSet: EmployeeLeaveUpdateDto) {
    return coreApi.post(`${controller}/EmployeeLeaves`, leaveSet);
}
